<template>
  <div class="Authority">
    <Header title="项目列表" index="首页" titleOne="项目管理" beforeTitle="项目列表" />
    <div class="content">
      <div class="topUsers">
        <div>
          <el-select
            placeholder="请选择项目类型"
            v-model="xmlistParams.project_type"
            @change="getproductType()"
          >
            <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-input
            v-model="xmlistParams.keywords"
            placeholder="请输入企业名称关键字"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-button class="search" @click="handleSearch()">查询</el-button>
        </div>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中···"
        :data="xmlist.data"
        style="width: 100%"
      >
        <el-table-column prop="order_name" label="项目名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.order_name ? scope.row.order_name : "-" }}</template>
        </el-table-column>
        <el-table-column prop="company_name" label="企业名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.company_name ? scope.row.company_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="contract_name" label="合同名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.contract_name ? scope.row.contract_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="product_type_name" label="项目分类" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.product_type_name ? scope.row.product_type_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="end_time" label="截止时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.end_time ? scope.row.end_time : "-" }}</template>
        </el-table-column>
        <el-table-column prop="order_year" label="年度" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.order_year ? scope.row.order_year : "-" }}</template>
        </el-table-column>
        <el-table-column prop="helper" label="协助人" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.helper ? scope.row.helper : "-" }}</template>
        </el-table-column>
        <el-table-column prop="status" label="状态" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span style="color:red" class="state" v-if="scope.row.status == 4 ">
              <i></i>已终止
            </span>
            <span class="state whx" v-if="scope.row.status == 3">
              <i></i>未通过
            </span>
            <span class="state yhx" v-if="scope.row.status == 2">
              <i></i>已通过
            </span>
            <span class="state ytk" v-if="scope.row.status == 1">
              <i></i>进行中
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="progress_rate" label="项目进度">
          <template slot-scope="scope">
            <el-progress type="line" :stroke-width="8" :percentage="scope.row.progress_rate * 100"></el-progress>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="150px">
          <template slot-scope="scope">
            <el-button
              v-if="privilige_list.order.includes('continue')"
              class="edit"
              size="mini"
              @click="handleProcess(scope.row.id)"
            >流程</el-button>
            <el-button
              v-if="privilige_list.order.includes('edit')"
              class="edit"
              size="mini"
              @click="handleEdit(scope.row.id)"
            >编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-if="xmlist.total > 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="xmlistParams.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="xmlistParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="xmlist.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  inject: ["reload"],
  data () {
    return {
      opt: { id: "0", name: "全部" },
      loading: true,
      xmlistParams: {
        page: 1,
        limit: 10,
        keywords: "",
        company_name: "",
        project_type: "",
        order: "id desc",
      },

      options: [],
      privilige_list: {}
    };
  },
  activated () {
    // this.handleSearch();
    this.xmlistParams
    this.$store.dispatch("getXMList", this.xmlistParams);
  },
  mounted () {
    this.getproductType();
  },
  computed: {
    ...mapState(["xmlist"]),
  },
  created () {
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
    this.$store.dispatch("getXMList", this.xmlistParams);
  },
  methods: {
    handleSizeChange (val) {
      this.xmlistParams.limit = val;
      this.$store.dispatch("getXMList", this.xmlistParams);
    },
    handleCurrentChange (val) {
      this.xmlistParams.page = val;
      this.$store.dispatch("getXMList", { ...this.xmlistParams });
    },
    handleSearch () {
      this.$store.dispatch("getXMList", { ...this.xmlistParams, pahe: '', limit: '' });
      this.xmlistParams.page = 1
    },
    getproductType () {
      this.axios.get("/api/policy/select_product_type").then((res) => {
        this.options = res.data;
        this.options.unshift(this.opt);
        this.$store.dispatch("getXMList", this.xmlistParams);
      });
    },
    handleProcess (id) {
      this.$router.push("/project/process?id=" + id);
    },
    handleEdit (id) {
      this.$router.push("/project/info?id=" + id);
    },
  },
  beforeUpdate () {
    this.loading = false;
  },
};
</script>

<style scoped="scoped">
.whx {
  color: #ff6f7a;
  font-weight: bold;
}
.yhx {
  color: #cacddf;
  font-weight: bold;
}
.ytk {
  color: #ffbe2c;
  font-weight: bold;
}
.el-progress__text {
  color: #0f38ff;
  font-weight: bold;
  font-size: 14px;
}
</style>
